import {
  Accessor,
  AddGuidePractitioner,
  AddPractitioner,
  AddTreatmentPlan,
  AttachmentSuccess,
  CheckoutSessionArgs,
  GetAttachmentsArgs,
  GetPatientOverview,
  GetPatientOverviewArgs,
  GuidePractitioner,
  GuidePractitionerPatch,
  MultiAddAttachments,
  MultiAttachmentUrl,
  MultiPresignedUrl,
  PatchPracticeArgs,
  PatchPracticeMembersArgs,
  PatchPractitionersArgs,
  PatchTreatmentGuideArgs,
  Patient,
  PatientSignUp,
  PostAttachments,
  PostAttachmentsArgs,
  PostDividebuyRedirectArgs,
  PostPracticeMembers,
  PostPracticeMembersArgs,
  PostPractitioners,
  PostPractitionersArgs,
  PostTreatmentGuides,
  PostTreatmentGuidesArgs,
  PracticeDetails,
  PracticeDetailsPatch,
  Practitioner,
  PractitionerDetailsPatch,
  PresignedUrl,
  SendPlanArgs,
  StripeSession,
  TreatmentGuide,
  TreatmentGuideId,
  TreatmentPlanSuccess,
  TreatmentPlanUrl,
  UpdateIsArchivedArgs,
  UpdateTreatmentGuideArgs,
} from '@shared/data/types'
import { patientFetch, practiceFetch as fetch, practiceFetch, unprotectedFetch } from '@shared/utils/fetch'
import { apiUrls, newApiBaseUrl, newApiUrls } from '@shared/utils/urls'

import { accessFetch } from './queries'

export const fetchers = {
  PRACTICE_DETAILS: ({ practiceId, ...data }: PracticeDetailsPatch): Promise<PracticeDetails> =>
    fetch({ url: apiUrls.practiceDetails(practiceId || ''), method: 'patch', data }),
  PRACTITIONER_ADD_USER: (data: AddPractitioner): Promise<Practitioner> =>
    fetch({ url: apiUrls.practitionerSignUpUser, method: 'post', data }),
  PRACTITIONER_UPDATE_DETAILS: ({ practitionerId, ...data }: PractitionerDetailsPatch): Promise<Practitioner> =>
    fetch({ url: apiUrls.practitionerUpdateDetails(practitionerId || ''), method: 'patch', data }),
  GUIDE_PRACTITIONER_ADD: (data: AddGuidePractitioner): Promise<AddGuidePractitioner> =>
    fetch({ url: apiUrls.guidePractitionerAdd, method: 'post', data }),
  GUIDE_PRACTITIONER_UPDATE: ({ id, ...data }: GuidePractitionerPatch): Promise<GuidePractitioner> =>
    fetch({ url: apiUrls.guidePractitionerUpdate(id || ''), method: 'patch', data }),
  PRACTITIONER_CREATE_TREATMENT_GUIDE: (data: TreatmentGuide): Promise<TreatmentGuideId> =>
    fetch({ url: apiUrls.practitionerTreatmentGuide, method: 'post', data }),
  PRACTITIONER_UPDATE_TREATMENT_GUIDE: (data: UpdateTreatmentGuideArgs): Promise<TreatmentGuideId> =>
    fetch({ url: apiUrls.practitionerTreatmentGuide, method: 'put', data }),
  PRACTITIONER_PATIENT_SIGNUP: (data: PatientSignUp): Promise<Patient> =>
    fetch({ url: apiUrls.patientSignUp, method: 'post', data }),
  PRACTITIONER_TREATMENT_PLAN_URL: (data: TreatmentPlanUrl): Promise<PresignedUrl> =>
    fetch({ url: apiUrls.practitionerTreatmentPlanUrl, method: 'post', data }),
  PRACTITIONER_ADD_TREATMENT_PLAN: ({ filename, ...data }: AddTreatmentPlan): Promise<TreatmentPlanSuccess> =>
    fetch({ url: apiUrls.practitionerAddTreatmentPlan(filename), method: 'patch', data }),
  PRACTITIONER_REMOVE_TREATMENT_PLAN: ({ filename }: { filename: string }): Promise<void> =>
    fetch({ url: apiUrls.practitionerAddTreatmentPlan(filename), method: 'delete' }),
  PRACTITIONER_UPDATE_IS_ARCHIVED: (data: UpdateIsArchivedArgs): Promise<TreatmentGuideId> =>
    fetch({ url: apiUrls.practitionerUpdateIsArchived, method: 'put', data }),
  PRACTITIONER_RESEND_INVITE: (data: TreatmentGuideId): Promise<void> =>
    fetch({ url: apiUrls.practitionerResendInvite, method: 'post', data }),
  PRACTITIONER_ATTACHMENTS_URL: (data: MultiAttachmentUrl): Promise<MultiPresignedUrl> =>
    fetch({ url: apiUrls.practitionerAttachmentsUrl, method: 'post', data }),
  PRACTITIONER_ADD_ATTACHMENTS: (data: MultiAddAttachments): Promise<AttachmentSuccess> =>
    fetch({ url: apiUrls.practitionerAddAttachments, method: 'patch', data }),
  PRACTITIONER_REMOVE_ATTACHMENTS: (data: MultiAddAttachments): Promise<void> =>
    fetch({ url: apiUrls.practitionerAddAttachments, method: 'delete', data }),
  PRACTITIONER_ACCOUNT_PAYMENTS: (data: CheckoutSessionArgs): Promise<StripeSession> =>
    fetch({ url: apiUrls.practitionerAccountPayments, method: 'post', data }),
  PRACTITIONER_SEND_PLAN: (data: SendPlanArgs): Promise<void> =>
    fetch({ url: apiUrls.practitionerSendPlan, method: 'post', data }),

  // mutations for new BE API

  PATCH_PRACTICE: ({ practiceId, ...data }: PatchPracticeArgs): Promise<void> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.practices}/${practiceId}`,
      data,
    }),
  POST_PRACTITIONERS: (data: PostPractitionersArgs): Promise<PostPractitioners> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.practitioners}`,
      data,
    }),
  POST_PRACTICE_MEMBERS: ({ practiceId, ...data }: PostPracticeMembersArgs): Promise<PostPracticeMembers> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.members}`,
      data,
    }),
  PATCH_PRACTICE_MEMBERS: ({ practiceId, notificationsEnabled, userId }: PatchPracticeMembersArgs): Promise<void> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.members}/${userId}`,
      data: { notificationsEnabled },
    }),
  PATCH_PRACTITIONERS: ({ practitionerId, ...data }: PatchPractitionersArgs): Promise<void> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.practitioners}/${practitionerId}`,
      data,
    }),
  POST_TREATMENT_GUIDES: (data: PostTreatmentGuidesArgs): Promise<PostTreatmentGuides> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.treatmentGuides}`,
      data,
    }),
  PATCH_TREATMENT_GUIDE: ({ id, ...data }: PatchTreatmentGuideArgs, accessor: Accessor): Promise<void> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'PATCH',
      url: `${newApiUrls.treatmentGuides}/${id}`,
      data: {
        ...data,
      },
    })
  },
  POST_AUTH_S3_PUT_OBJECT: (data: PostAttachmentsArgs): Promise<PostAttachments & PostAttachmentsArgs> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.s3}/put-object`,
      data,
    }),
  GET_AUTH_S3_PUT_OBJECT: (data: GetAttachmentsArgs, accessor: Accessor): Promise<{ data: { url: string } }> =>
    accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.s3}/get-object`,
      data,
    }),
  GET_PATIENT_OVERVIEW: ({ practiceId, ...data }: GetPatientOverviewArgs): Promise<GetPatientOverview> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.patientOverview}`,
      params: {
        ...data,
      },
    }),
  POST_DIVIDEBUY_REDIRECT: (data: PostDividebuyRedirectArgs) =>
    patientFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.dividebuy}${newApiUrls.redirect}`,
      data,
    }),
  POST_MAGIC_LINK: (data: { email: string }, accessor: Accessor) => {
    const fetch = accessor === Accessor.PRACTICE_MEMBER ? practiceFetch : unprotectedFetch
    return fetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.magicLink}`,
      data,
    })
  },
  POST_DIVIDEBUY_SOFT_SEARCH: (data: { amount: number }) =>
    patientFetch({
      baseURL: newApiBaseUrl,
      method: 'POST',
      url: `${newApiUrls.auth}${newApiUrls.dividebuy}${newApiUrls.softSearch}`,
      data,
    }),
}

export const mutations = {
  PRACTICE_DETAILS: {
    mutationFn: (data: PracticeDetailsPatch) => fetchers.PRACTICE_DETAILS(data),
  },
  PRACTITIONER_ADD: {
    mutationFn: (data: AddPractitioner) => fetchers.PRACTITIONER_ADD_USER(data),
  },
  PRACTITIONER_UPDATE: {
    mutationFn: (data: PractitionerDetailsPatch) => fetchers.PRACTITIONER_UPDATE_DETAILS(data),
  },
  GUIDE_PRACTITIONER_ADD: {
    mutationFn: (data: AddGuidePractitioner) => fetchers.GUIDE_PRACTITIONER_ADD(data),
  },
  GUIDE_PRACTITIONER_UPDATE: {
    mutationFn: (data: GuidePractitionerPatch) => fetchers.GUIDE_PRACTITIONER_UPDATE(data),
  },
  PRACTITIONER_CREATE_TREATMENT_GUIDE: {
    mutationFn: (data: TreatmentGuide) => fetchers.PRACTITIONER_CREATE_TREATMENT_GUIDE(data),
  },
  PRACTITIONER_UPDATE_TREATMENT_GUIDE: {
    mutationFn: (data: UpdateTreatmentGuideArgs) => fetchers.PRACTITIONER_UPDATE_TREATMENT_GUIDE(data),
  },
  PRACTITIONER_PATIENT_SIGNUP: {
    mutationFn: (data: PatientSignUp) => fetchers.PRACTITIONER_PATIENT_SIGNUP(data),
  },
  PRACTITIONER_TREATMENT_PLAN_URL: {
    mutationFn: (data: TreatmentPlanUrl) => fetchers.PRACTITIONER_TREATMENT_PLAN_URL(data),
  },
  PRACTITIONER_ADD_TREATMENT_PLAN: {
    mutationFn: (data: AddTreatmentPlan) => fetchers.PRACTITIONER_ADD_TREATMENT_PLAN(data),
  },
  PRACTITIONER_REMOVE_TREATMENT_PLAN: {
    mutationFn: (data: { filename: string }) => fetchers.PRACTITIONER_REMOVE_TREATMENT_PLAN(data),
  },
  PRACTITIONER_UPDATE_IS_ARCHIVED: {
    mutationFn: (data: UpdateIsArchivedArgs) => fetchers.PRACTITIONER_UPDATE_IS_ARCHIVED(data),
  },
  PRACTITIONER_RESEND_INVITE: {
    mutationFn: (data: TreatmentGuideId) => fetchers.PRACTITIONER_RESEND_INVITE(data),
  },
  PRACTITIONER_ATTACHMENTS_URL: {
    mutationFn: (data: MultiAttachmentUrl) => fetchers.PRACTITIONER_ATTACHMENTS_URL(data),
  },
  PRACTITIONER_ADD_ATTACHMENTS: {
    mutationFn: (data: MultiAddAttachments) => fetchers.PRACTITIONER_ADD_ATTACHMENTS(data),
  },
  PRACTITIONER_REMOVE_ATTACHMENTS: {
    mutationFn: (data: MultiAddAttachments) => fetchers.PRACTITIONER_REMOVE_ATTACHMENTS(data),
  },
  PRACTITIONER_ACCOUNT_PAYMENTS: {
    mutationFn: (data: CheckoutSessionArgs) => fetchers.PRACTITIONER_ACCOUNT_PAYMENTS(data),
  },
  PRACTITIONER_SEND_PLAN: {
    mutationFn: (data: SendPlanArgs) => fetchers.PRACTITIONER_SEND_PLAN(data),
  },
  // mutations for new backend API
  POST_PRACTITIONERS: {
    mutationFn: (data: PostPractitionersArgs) => fetchers.POST_PRACTITIONERS(data),
  },
  PATCH_PRACTITIONERS: {
    mutationFn: (data: PatchPractitionersArgs) => fetchers.PATCH_PRACTITIONERS(data),
  },
  POST_PRACTICE_MEMBERS: {
    mutationFn: (data: PostPracticeMembersArgs) => fetchers.POST_PRACTICE_MEMBERS(data),
  },
  PATCH_PRACTICE_MEMBERS: {
    mutationFn: (data: PatchPracticeMembersArgs) => fetchers.PATCH_PRACTICE_MEMBERS(data),
  },
  PATCH_PRACTICE: {
    mutationFn: (data: PatchPracticeArgs) => fetchers.PATCH_PRACTICE(data),
  },
  POST_TREATMENT_GUIDES: {
    mutationFn: (data: PostTreatmentGuidesArgs) => fetchers.POST_TREATMENT_GUIDES(data),
  },
  PATCH_TREATMENT_GUIDES: {
    mutationFn: (data: PatchTreatmentGuideArgs, accessor: Accessor) => fetchers.PATCH_TREATMENT_GUIDE(data, accessor),
  },
  POST_AUTH_S3_PUT_OBJECT: {
    mutationFn: (data: PostAttachmentsArgs) => fetchers.POST_AUTH_S3_PUT_OBJECT(data),
  },
  GET_AUTH_S3_PUT_OBJECT: {
    mutationFn: (data: GetAttachmentsArgs, accessor: Accessor) => fetchers.GET_AUTH_S3_PUT_OBJECT(data, accessor),
  },
  GET_PATIENT_OVERVIEW: {
    mutationFn: (data: GetPatientOverviewArgs) => fetchers.GET_PATIENT_OVERVIEW(data),
  },
  POST_DIVIDEBUY_REDIRECT: {
    mutationFn: (data: PostDividebuyRedirectArgs) => fetchers.POST_DIVIDEBUY_REDIRECT(data),
  },
  POST_MAGIC_LINK: {
    mutationFn: (data: { email: string }, accessor: Accessor) => fetchers.POST_MAGIC_LINK(data, accessor),
  },
  POST_DIVIDEBUY_SOFT_SEARCH: {
    mutationFn: (data: { amount: number }) => fetchers.POST_DIVIDEBUY_SOFT_SEARCH(data),
  },
}
