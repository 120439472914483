import { lazy } from 'react'
import { Outlet, Route } from 'react-router-dom'

const PracticeLoginPage = lazy(() => import('@practice/pages/LoginPage'))
const ErrorPage = lazy(() => import('@shared/pages/ErrorPage'))
const Steps = lazy(() => import('@practice/pages/Onboarding/Steps'))
const PatientProfile = lazy(() => import('@practice/pages/PatientsPages/PatientProfile'))
const PatientsDashboard = lazy(() => import('@practice/pages/PatientsPages/PatientsDashboard'))
const PracticeResetPasswordPage = lazy(() => import('@practice/pages/ResetPasswordPage'))
const PracticeAccountSuccess = lazy(() => import('@practice/pages/AccountSuccess'))
const PracticeSupport = lazy(() => import('@practice/pages/Support'))
const ProtectedDashboardLayout = lazy(() => import('@practice/templates/ProtectedDashboardLayout'))
const PracticeOnboardingLayout = lazy(() => import('@practice/templates/OnboardingLayout'))
const PracticeTreatmentPreviewLayout = lazy(() => import('@practice/templates/PreviewLayout'))
const AuthLayout = lazy(() => import('@practice/templates/AuthLayout/'))
const ForgotPasswordConfirm = lazy(() => import('@practice/pages/ForgotPasswordPage/Confirm'))
const ForgotPasswordSendCode = lazy(() => import('@practice/pages/ForgotPasswordPage/SendCode'))
const CookieDeclarationPage = lazy(() => import('@practice/pages/CookieDeclarationPage'))
const Account = lazy(() => import('@practice/pages/AccountPage'))
const PatientInvitePage = lazy(() => import('@practice/pages/PatientInvitePage'))
const UpdateTreatmentGuidePage = lazy(() => import('@practice/pages/UpdateTreatmentGuide'))
const TreatmentPreview = lazy(() => import('@practice/pages/TreatmentPreview'))
const Payments = lazy(() => import('@practice/pages/PaymentsTable'))
const ViewTreatmentGuidePage = lazy(() => import('@practice/pages/ViewTreatmentGuidePage'))

import { routePaths } from '@shared/utils/urls'

import { RoutesCookieWrapper } from './components/RoutesCookieWrapper'

const DashboardRoutes = (
  <Route path={routePaths.dashboard} element={<ProtectedDashboardLayout />}>
    <Route index element={<PatientsDashboard />} />
    <Route path={routePaths.payments} element={<Payments />} />
    <Route index path={routePaths.patientInvite} element={<PatientInvitePage />} />
    <Route path={routePaths.account} element={<Account />} />
    <Route path={routePaths.support} element={<PracticeSupport />} />
    <Route path={routePaths.patients} element={<Outlet />}>
      <Route path={routePaths.patientId} element={<Outlet />}>
        <Route index element={<PatientProfile />} />
      </Route>
    </Route>
    <Route path={routePaths.treatmentGuides} element={<Outlet />}>
      <Route path={routePaths.treatmentGuideId}>
        <Route index element={<UpdateTreatmentGuidePage />} />
      </Route>
    </Route>
  </Route>
)

const OnboardingRoutes = (
  <Route path={routePaths.onboarding} element={<PracticeOnboardingLayout />}>
    <Route path={routePaths.stepNumber} element={<Steps />} />
  </Route>
)

const PreviewRoutes = (
  <Route path={routePaths.treatmentPreview} element={<PracticeTreatmentPreviewLayout />}>
    <Route index element={<TreatmentPreview />} />
  </Route>
)

const PracticeRoutes = (
  <Route path={routePaths.practice} element={<RoutesCookieWrapper />}>
    <Route path={routePaths.error} element={<ErrorPage />} />
    <Route path={routePaths.cookieDeclaration} element={<CookieDeclarationPage />} />
    <Route path={routePaths.accountSuccess} element={<PracticeAccountSuccess />} />
    <Route path={routePaths.login} element={<AuthLayout />}>
      <Route index element={<PracticeLoginPage />} />
      <Route path={routePaths.resetPassword} element={<PracticeResetPasswordPage />} />
      <Route path={routePaths.forgotPassword} element={<ForgotPasswordSendCode />} />
      <Route path={routePaths.forgotPasswordConfirm} element={<ForgotPasswordConfirm />} />
    </Route>
    {OnboardingRoutes}
    {DashboardRoutes}
    {PreviewRoutes}
    <Route path={routePaths.practiceViewTreatmentGuide} element={<PracticeTreatmentPreviewLayout />}>
      <Route index element={<ViewTreatmentGuidePage />} />
    </Route>
  </Route>
)

export default PracticeRoutes
