import { QueryFunctionContext } from '@tanstack/react-query'

import {
  Accessor,
  DivideBuyInstalment,
  FileUrl,
  GetPracticeInstalments,
  GetPracticeMembers,
  GetPractitioners,
  GetTreatmentDefinition,
  GetTreatmentDefinitions,
  GuidePractitioner,
  ITreatmentGuide,
  PatientInfo,
  PatientUserDetails,
  PostAttachments,
  Practice,
  PracticeDetails,
  PracticePatients,
  PracticePratitioners,
  PracticePublic,
  PractitionerUserDetails,
  TreatmentDefinition,
  User,
} from '@shared/data/types'
import { patientFetch, practiceFetch as fetch, unprotectedFetch } from '@shared/utils/fetch'
import { apiUrls, newApiBaseUrl, newApiUrls } from '@shared/utils/urls'

interface QueryArgs {
  queryKey: string[]
}

export interface QueryKey {
  queryKey: string[]
}

export const accessFetch = (accessor: Accessor) => (accessor === Accessor.PRACTICE_MEMBER ? fetch : patientFetch)

const fetchers = {
  PRACTITIONER_PATIENT_INFO: ({ queryKey }: QueryArgs): Promise<PatientInfo> =>
    fetch({ url: apiUrls.patientInfo(queryKey[1]) }),
  PATIENT_USER_DETAILS: (): Promise<PatientUserDetails> => fetch({ url: apiUrls.patientUserDetails }),
  PRACTITIONER_USER_DETAILS: (): Promise<PractitionerUserDetails> => fetch({ url: apiUrls.practitionerUserDetails }),
  PRACTICE_PATIENT_LIST: (): Promise<PracticePatients> => fetch({ url: apiUrls.patientList }),
  PRACTICE_PRACTITIONER_LIST: (): Promise<PracticePratitioners> => fetch({ url: apiUrls.practitionerList }),
  TREATMENT_DEFINITION: ({ queryKey }: QueryArgs): Promise<TreatmentDefinition> =>
    fetch({ url: apiUrls.treatmentDefinition(queryKey[1]) }),
  PRACTICE_DETAILS: ({ queryKey }: QueryArgs): Promise<PracticeDetails> =>
    fetch({ url: apiUrls.practiceDetails(queryKey[1]) }),
  PRACTICE_DETAILS_UNPROTECTED: ({ queryKey }: QueryArgs): Promise<PracticeDetails> =>
    unprotectedFetch({ url: apiUrls.practiceDetailsUnprotected(queryKey[1]) }),
  PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED: ({ queryKey }: QueryArgs): Promise<DivideBuyInstalment[]> =>
    unprotectedFetch({ url: apiUrls.practiceAvailableInstalmentsUnprotected(queryKey[1], queryKey[2]) }),
  GUIDE_PRACTITIONER: ({ queryKey }: QueryArgs): Promise<GuidePractitioner[]> =>
    fetch({ url: apiUrls.practiceGuidePractitionerGet(queryKey[1]) }),
  PRACTITIONER_TREATMENT_PLAN: ({ queryKey }: QueryArgs): Promise<FileUrl> | false => {
    if (queryKey[1]) {
      return fetch({ url: apiUrls.patientTreatmentPlan(queryKey[1]) })
    }
    return false
  },
  PRACTICE_AVAILABLE_INSTALMENTS: ({ queryKey }: QueryArgs): Promise<DivideBuyInstalment[]> =>
    fetch({
      method: 'POST',
      url: apiUrls.practiceAvailableInstalments,
      data: { orderTotal: queryKey[1], treatmentGuideId: queryKey[2] },
    }),
  // fetchers for new backend API
  GET_AUTH_USER: (_: QueryKey, accessor: Accessor): Promise<User> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.auth}${newApiUrls.user}`,
    })
  },
  GET_PRACTICE_MEMBERS: ({ queryKey }: QueryArgs): Promise<GetPracticeMembers> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${queryKey[1]}${newApiUrls.members}`,
    }),
  GET_PRACTICE: (_context: QueryKey, practiceId: string, accessor: Accessor): Promise<Practice> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${practiceId}`,
    })
  },
  GET_PRACTITIONERS: ({ queryKey }: QueryArgs): Promise<GetPractitioners> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practitioners}`,
      params: {
        practiceId: queryKey[1],
      },
    }),
  GET_TREATMENT_GUIDES: (_: QueryKey, treatmentGuideId: string, accessor: Accessor): Promise<ITreatmentGuide> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.treatmentGuides}/${treatmentGuideId}`,
    })
  },
  GET_TREATMENT_DEFINITION: (
    _queryContext: QueryKey,
    treatmentDefinitionId: string,
    accessor: Accessor,
  ): Promise<GetTreatmentDefinition> => {
    return accessFetch(accessor)({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: treatmentDefinitionId
        ? `${newApiUrls.treatmentDefinitions}/${treatmentDefinitionId}`
        : `${newApiUrls.treatmentDefinitions}`,
    })
  },
  GET_TREATMENT_DEFINITIONS: (): Promise<GetTreatmentDefinitions> =>
    fetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.treatmentDefinitions}`,
    }),
  GET_S3_OBJECT: ({ queryKey }: QueryArgs): Promise<PostAttachments> =>
    fetch({
      baseURL: newApiBaseUrl,
      url: `${newApiUrls.auth}${newApiUrls.s3}/put-object`,
      method: 'POST',
      data: { contentType: queryKey[1] },
    }),
  GET_PRACTICE_PUBLIC: ({ queryKey }: { queryKey: string[] }): Promise<PracticePublic> =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      method: 'GET',
      url: `${newApiUrls.practices}/${queryKey[1]}/public`,
    }),
  GET_PRACTICE_INSTALMENTS: (
    _query: QueryFunctionContext<string[]>,
    amount: string,
    practiceId: string,
  ): Promise<GetPracticeInstalments> =>
    unprotectedFetch({
      baseURL: newApiBaseUrl,
      url: `${newApiUrls.practices}/${practiceId}${newApiUrls.dividebuy}${newApiUrls.availableInstalments}`,
      method: 'GET',
      params: {
        amount,
      },
    }).then((res) => {
      return { data: Object.values(res.data) }
    }),
}

export const queries = {
  PRACTITIONER_PATIENT_INFO: {
    queryKey: ['PRACTITIONER_PATIENT_INFO'],
    queryFn: fetchers.PRACTITIONER_PATIENT_INFO,
  },
  PRACTICE_PATIENT_LIST: {
    queryKey: ['PRACTICE_PATIENT_LIST'],
    queryFn: fetchers.PRACTICE_PATIENT_LIST,
  },
  PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED: {
    queryKey: ['PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED'],
    queryFn: fetchers.PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED,
  },
  PRACTICE_PRACTITIONER_LIST: {
    queryKey: ['PRACTICE_PRACTITIONER_LIST'],
    queryFn: fetchers.PRACTICE_PRACTITIONER_LIST,
  },
  PRACTICE_DETAILS: {
    queryKey: ['PRACTICE_DETAILS'],
    queryFn: fetchers.PRACTICE_DETAILS,
  },
  PRACTICE_DETAILS_UNPROTECTED: {
    queryKey: ['PRACTICE_DETAILS_UNPROTECTED'],
    queryFn: fetchers.PRACTICE_DETAILS_UNPROTECTED,
  },
  PRACTITIONER_USER_DETAILS: {
    queryKey: ['PRACTITIONER_USER_DETAILS'],
    queryFn: fetchers.PRACTITIONER_USER_DETAILS,
  },
  TREATMENT_DEFINITION: {
    queryKey: ['TREATMENT_DEFINITION'],
    queryFn: fetchers.TREATMENT_DEFINITION,
  },
  PRACTITIONER_TREATMENT_PLAN: {
    queryKey: ['PRACTITIONER_TREATMENT_PLAN'],
    queryFn: fetchers.PRACTITIONER_TREATMENT_PLAN,
  },
  GUIDE_PRACTITIONER: {
    queryKey: ['GUIDE_PRACTITIONER'],
    queryFn: fetchers.GUIDE_PRACTITIONER,
  },
  PRACTICE_AVAILABLE_INSTALMENTS: {
    queryKey: ['PRACTICE_AVAILABLE_INSTALMENTS'],
    queryFn: fetchers.PRACTICE_AVAILABLE_INSTALMENTS,
  },
  // queries for new backend API
  GET_AUTH_USER: {
    queryKey: ['GET_AUTH_USER'],
    queryFn: fetchers.GET_AUTH_USER,
  },
  GET_PRACTICE_MEMBERS: {
    queryKey: ['GET_PRACTICE_MEMBERS'],
    queryFn: fetchers.GET_PRACTICE_MEMBERS,
  },
  GET_PRACTICE: {
    queryKey: ['GET_PRACTICE'],
    queryFn: fetchers.GET_PRACTICE,
  },
  GET_PRACTITIONERS: {
    queryKey: ['GET_PRACTITIONERS'],
    queryFn: fetchers.GET_PRACTITIONERS,
  },
  GET_TREATMENT_GUIDES: {
    queryKey: ['GET_TREATMENT_GUIDES'],
    queryFn: fetchers.GET_TREATMENT_GUIDES,
  },
  GET_TREATMENT_DEFINITION: {
    queryKey: ['GET_TREATMENT_DEFINITION'],
    queryFn: fetchers.GET_TREATMENT_DEFINITION,
  },
  GET_TREATMENT_DEFINITIONS: {
    queryKey: ['GET_TREATMENT_DEFINITIONS'],
    queryFn: fetchers.GET_TREATMENT_DEFINITIONS,
  },
  GET_S3_OBJECT: {
    queryKey: ['GET_S3_OBJECT'],
    queryFn: fetchers.GET_S3_OBJECT,
  },
  GET_PRACTICE_PUBLIC: {
    queryKey: ['GET_PRACTICE_PUBLIC'],
    queryFn: fetchers.GET_PRACTICE_PUBLIC,
  },
  GET_PRACTICE_INSTALMENTS: {
    queryKey: ['GET_PRACTICE_INSTALMENTS'],
    queryFn: fetchers.GET_PRACTICE_INSTALMENTS,
  },
}
